import { Link } from "@StarberryUtils";
import React,{useState,useEffect} from "react"
import {Button, Form, Container, Row, Col,Nav,Tab} from 'react-bootstrap';
// Style
import  './ValuationTwo.scss';
const ValuationTwo = ( props ) => {
  const [ReadMoreText, ReadMoreLess] = useState(false);

  const ReadMore=()=>{
    ReadMoreLess(!ReadMoreText)
  }
  return(
    <React.Fragment>
    <div className="valuation-form">
      <Container>
        <Row>
        <Col lg="12">
          <div className="valuation-form-block">
          <div className="valuation-form-heading">
          <h1>Book your valuation</h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit lipsum.</p>
          </div>
          <div className="valuation-form-tabing">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">

  
      <Nav variant="pills" className="flex-column flex-md-row justify-content-md-between">
        <Nav.Item>
          <Nav.Link eventKey="first">I want to Sell</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="second">I want to Let</Nav.Link>
        </Nav.Item>
      </Nav>
  
      <Tab.Content>
        <Tab.Pane eventKey="first">
          <div className="valuation-block">
           <Form>
           <Form.Group>
            <Form.Label>First Name</Form.Label>
            <Form.Control type="text" placeholder="Christopher Rowe" />
          </Form.Group>
          <Form.Group>
          <Form.Label>Email Address</Form.Label>
            <Form.Control type="email" placeholder="chrisr@starberry.tv" />
          </Form.Group>
          <Form.Group>
          <Form.Label>Telephone Number</Form.Label>
            <Form.Control type="text" placeholder="07891292834" />
          </Form.Group>
          <Form.Group>
            <Form.Label>Property Postcode</Form.Label>
            <Form.Control type="text" placeholder="ST14 7AN" />
          </Form.Group>
          
        <div className="d-md-flex flex-box">
        <Form.Group>
        <Form.Label>Prefered Date</Form.Label>
           <div className="input_wrap">
            <i className="icon-date"></i>
            <Form.Control type="text" placeholder="12/02/2021" />
           </div>
          </Form.Group>
          <Form.Group>
          <Form.Label>Preferred Time</Form.Label>
          <div className="input_wrap">
           <i className="icon-time"></i>
            <Form.Control type="text" placeholder="09:00am" />
            </div>
          </Form.Group>
        </div>
          <div className="form-block-text">
            <label className="form-label">Your Monthly Payment</label>
            <div className="price-block">
              <span className="price-text">£1,230.00</span>
              <small>per month</small>
            </div>
            <h2>Ready to talk with one mortgage advisor?</h2>
            <p>By clicking Submit, you agree to our <Link href="#">Terms & Conditions</Link> and <Link href="#">Privacy Policy.</Link></p>
          </div>
          <Button variant="primary" className="btn">Book Appointment</Button>
           </Form>
          </div>
          </Tab.Pane>
        <Tab.Pane eventKey="second">
          <div className="valuation-block">
          <p>No Content</p>
          </div>
        </Tab.Pane>
      </Tab.Content>
  
</Tab.Container>
          </div>
          </div>
       </Col>
        </Row>
       
      </Container>
    </div>
      </React.Fragment>  
  
)
  }



export default ValuationTwo