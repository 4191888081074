import React from "react";
import Header from '../../components/Header/Header'
import ValuationSection from '../../components/ValuationTwo/ValuationTwo'
import Footer from '../../components/Footer/Footer'


const ValuationTwo =()=> {
  const [state,setState] = React.useState({
    showMenu:false
})

const handlerClick = () => {

        console.log('okkk')
        setState({...state,showMenu:!state.showMenu})
        var root = document.getElementsByTagName('html')[0];
        if (state.showMenu == true) {
            document.body.className = ''; 
            root.className = ''; 
        } else {
            document.body.classList.add("overflow-hidden");   
            root.classList.add("overflow-hidden");  
        }
   
}

return(
    <>
    <div className={state.showMenu ? "wrapper header-style2 open-search-block" : "wrapper header-style2"}>
     <Header showMenu={state.showMenu} handlerClick={handlerClick}/>
  
    
        <ValuationSection />
        <Footer />
        </div>  
        </>
    )
}

export default ValuationTwo;